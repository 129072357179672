import { useEffect, useState } from "react";
import { Box, Button, Group, Modal, TextInput, Text, ColorInput, Accordion, Divider } from "@mantine/core";
import { useForm } from "@mantine/form";
import { IconClock } from "@tabler/icons-react";
import { useGetGroup, useUpdateGroup } from "../../hooks/useMemberGroups";
import WeeklyTimePicker, { cleanSchedule, convertStringToDate, Schedule } from "../DateAndTime/WeeklyTimePicker";

interface FormValues {
	name: string;
	color: string;
	leader: string;
	schedule: Schedule;
}

interface GroupEditModalProps {
	groupId: string | null;
	isOpened: boolean;
	callback: () => void;
}

function GroupEditModal({ groupId, isOpened, callback }: GroupEditModalProps) {
	const [groupLoaded, setGroupLoaded] = useState(false);
	const [initialSchedule, setInitialSchedule] = useState<Schedule>({
		PON: [],
		UTO: [],
		SRI: [],
		CET: [],
		PET: [],
		SUB: [],
		NED: [],
	});
	const [schedule, setSchedule] = useState<Schedule>(initialSchedule);

	const { getGroup } = useGetGroup();
	const { update } = useUpdateGroup();

	const groupForm = useForm<FormValues>({
		initialValues: {
			color: "",
			name: "",
			leader: "",
			schedule: initialSchedule,
		},
	});

	useEffect(() => {
		if (isOpened) {
			getGroup(groupId)
				.then(groupData => {

					groupForm.setValues({
						color: groupData.color,
						name: groupData.name,
						leader: groupData.leader || "",
						schedule: groupData.schedule,
					});

					const convertedSchedule = Object.keys(groupData.schedule).reduce((newSchedule, day) => {
						newSchedule[day] = groupData.schedule[day].map(convertStringToDate);
						return newSchedule;
					}, {} as any);

					setInitialSchedule(convertedSchedule);
					//setData(data);
				})
		}
	}, [isOpened, groupId])

	// // Fetch group data when the modal is opened
	// useEffect(() => {
	//   const fetchGroupData = async () => {
	//     if (groupId && isOpened && !groupLoaded) {
	//       try {
	//         const groupData = await getGroup(groupId);

	//         const updatedSchedule = {
	//           PON: groupData.schedule.PON.map(convertStringToDate),
	//           UTO: groupData.schedule.UTO.map(convertStringToDate),
	//           SRI: groupData.schedule.SRI.map(convertStringToDate),
	//           CET: groupData.schedule.CET.map(convertStringToDate),
	//           PET: groupData.schedule.PET.map(convertStringToDate),
	//           SUB: groupData.schedule.SUB.map(convertStringToDate),
	//           NED: groupData.schedule.NED.map(convertStringToDate),
	//         };

	//         groupForm.setValues({
	//           color: groupData.color,
	//           name: groupData.name,
	//           leader: groupData.leader || "",
	//           schedule: updatedSchedule,
	//         });

	//         console.log(groupData.schedule)
	//         console.log(schedule);

	//         setSchedule(groupData.schedule);
	//         setGroupLoaded(true); // Mark group as loaded to avoid refetching
	//       } catch (error) {
	//         console.error("Failed to fetch group data:", error);
	//       }
	//     }
	//   };

	//   fetchGroupData();
	// }, [isOpened, groupId]);

	// Handle form submit
	const handleSubmit = async (values: FormValues) => {
		if (values.leader === "") {
			groupForm.setFieldValue("leader", "Nema");
		}

		// Clean schedule before saving
		const cleanedSchedule = cleanSchedule(schedule, true);

		if (await update({id: groupId, ...values, schedule: cleanedSchedule })) {
			groupForm.reset();
			setGroupLoaded(false); // Reset state so the form reloads data on next open
			callback();
		}
	};

	return (
		<>
			<Modal opened={isOpened} onClose={() => { setGroupLoaded(false); callback(); }} title="Uredi grupu">
				<Box sx={{ maxWidth: 600 }} mx="auto">
					<form onSubmit={groupForm.onSubmit((values) => handleSubmit(values))}>
						<ColorInput
							placeholder="Select group color"
							label="Group Color"
							format="hex"
							withAsterisk
							withPicker
							mb="xs"
							value={groupForm.values.color}
							onChange={(color) => groupForm.setFieldValue("color", color)}
						/>

						<TextInput withAsterisk label="Naziv" placeholder="Naziv grupe" mb="xs"
							{...groupForm.getInputProps('name')}
						/>

						<TextInput label="Voditelj" placeholder={`Voditelj / trener grupe`}
							{...groupForm.getInputProps('leader')}
						/>
						<Text c="dimmed" size="xs">Ostaviti prazno ukoliko nema trenera za ovu grupu.</Text>

						<Divider mt="lg" mb="lg" />

						<Accordion defaultValue="">
							<Accordion.Item value="customization">
								<Accordion.Control icon={<IconClock size={20} />}>
									<div>
										<Text>Dodavanje tjednog rasporeda</Text>
										<Text size="xs" color="dimmed" >
											Ostaviti prazno ukoliko se ne odnosi na ovu vrstu grupe.
										</Text>
									</div>
								</Accordion.Control>
								<Accordion.Panel>
									<WeeklyTimePicker onScheduleChange={setSchedule} verticalDisplay={false} initialSchedule={initialSchedule} />
								</Accordion.Panel>
							</Accordion.Item>
						</Accordion>

						<Group position="right" mt="md">
							<Button type="submit" variant="filled" mt="lg">
								Spremi promjene
							</Button>
						</Group>
					</form>
				</Box>
			</Modal>
		</>
	);
}

export default GroupEditModal;
