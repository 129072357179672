import { Button, Group, Text } from '@mantine/core';
import { useState, useEffect } from 'react';

const daysOfWeek = ['Ponedjeljak', 'Utorak', 'Srijeda', 'Četvrtak', 'Petak', 'Subota', 'Nedjelja'];

export function DaySelector({ startDay, setDay }: { startDay: number, setDay: (day: number) => void }) {
    const [selectedDayIndex, setSelectedDayIndex] = useState(startDay); // Start with the passed startDay

    // Update parent whenever the selected day changes
    useEffect(() => {
        setDay(selectedDayIndex); // Notify parent of the new day
    }, [selectedDayIndex, setDay]);

    const handlePreviousDay = () => {
        setSelectedDayIndex((prevIndex) => (prevIndex === 0 ? 6 : prevIndex - 1)); // Wrap around to Sunday if on Monday
    };

    const handleNextDay = () => {
        setSelectedDayIndex((prevIndex) => (prevIndex === 6 ? 0 : prevIndex + 1)); // Wrap around to Monday if on Sunday
    };

    return (
        <Group position="center" spacing="xs">
            <Button color="gray" size="xs" onClick={handlePreviousDay} compact variant="outline">
                &lt;
            </Button>
            <Text c="dimmed" tt="uppercase" fw={700} fz="xs" align="center" w="40%">{daysOfWeek[selectedDayIndex]}</Text>
            <Button color="gray" size="xs" onClick={handleNextDay} compact variant="outline">
                &gt;
            </Button>
        </Group>
    );
}
