import { getDefaultHeaders } from "./util/getRequestHeaders";

export async function FetchUsers() {
    let users = []
    let response = await fetch("/api/gym/getmembers", {
        method: "GET",
        headers: getDefaultHeaders(),
        body: JSON.stringify()
    });

    if (!response.ok) {
        throw new Error(response.statusText);
    }

    let response_json = await response.json();
    let user_data = response_json.users;


    users = user_data.map(user =>
    ({
        name: user.name,
        email: user.email,
        date_due: user.date_due,
        date_joined: user.date_joined,
        date_lastseen: user.date_lastseen,
        date_birth: user.date_birth,
        image: user.image,
        frozen_status: user.frozen_status,
        _id: user._id
    }));

    return users
}

export async function GetExpiredMembers(){
    let response = await fetch("/api/gym/expiredmembers", {
        method: "GET",
        headers: getDefaultHeaders(),
        body: JSON.stringify()
    });

    if (!response.ok) {
        throw new Error(response.statusText);
    }

    let response_json = await response.json();
    if (!response.ok) {
        throw new Error(response.statusText);
    }

    return response_json.expiredMembers
}

export async function FetchUserById(_id) {
    let response = await fetch("/api/gym/getmember", {
        method: "POST",
        headers: getDefaultHeaders(),
        body: JSON.stringify({ "_id": _id })
    });

    if (!response.ok) {
        throw new Error(response.statusText);
    }

    let response_json = await response.json();
    return response_json.user
}

export async function FetchUserByRFID(rfid) {
    let response = await fetch("/api/gym/getbyrfid", {
        method: "POST",
        headers: getDefaultHeaders(),
        body: JSON.stringify({ "rfid": rfid })
    });

    if (!response.ok) {
        throw new Error(response.statusText);
    }

    let response_json = await response.json();
    let success = response_json.success
    let user_object = response_json.user

    return [success, user_object]
}

export async function FetchSoonDueMembers(days) {
    let users = []

    let response = await fetch("/api/gym/soonDueMembers", {
        method: "POST",
        headers: getDefaultHeaders(),
        body: JSON.stringify({days})
    });
    if (!response.ok) {
        return null;
    }

    let response_json = await response.json();
    let user_data = response_json.users;
    users = user_data.map(user =>
    ({
        name: user.name,
        email: user.email,
        date_due: user.date_due,
        date_joined: user.date_joined,
        date_lastseen: user.date_lastseen,
        date_birth: user.date_birth,
        image: user.image,
        frozen_status: user.frozen_status,
        _id: user._id
    }));

    return users
}