import { ActionIcon, Avatar, Badge, Modal, ScrollArea, Tooltip, Text, Tabs } from "@mantine/core";
import { IconClock } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { FetchSoonDueMembers, GetExpiredMembers } from "../../hooks/MembersHook";
import ReturnImgSrc from "../../utils/imageSrc";
import SoonDueScroll from "./SoonDueScroll";

export default function SoonDueModal({ }) {
    const [dueOpened, setDueOpened] = useState(false);
    const [activeTab, setActiveTab] = useState<string | null>('first');
    const [dueDays, setDueDays] = useState(7);
    const [dueMembers, setDueMembers] = useState<any[]>([]);
    const [expiredMembers, setExpiredMembers] = useState<any[]>([]);
    const [dueMembersCount, setDueMembersCount] = useState(0);

    useEffect(() => {
        if (dueDays === -1) {
            GetExpiredMembers().then((data) => {
                if (data) {
                    var users = data.map(
                        (user: { name: string; image: Object; date_due: string }) => ({
                            label: user.name,
                            value: user.name,
                            date_due: user.date_due,
                            image: ReturnImgSrc(user.image),
                        })
                    );
                    setExpiredMembers(users);
                } else {
                    setExpiredMembers([]);
                }
            });
        } else {
            FetchSoonDueMembers(dueDays).then((data) => {
                if (data) {
                    var users = data.map(
                        (user: { name: string; image: Object; date_due: string }) => ({
                            label: user.name,
                            value: user.name,
                            date_due: user.date_due,
                            image: ReturnImgSrc(user.image),
                        })
                    );
                    setDueMembers(users);
                    setDueMembersCount(users.length);
                } else {
                    setDueMembers([]);
                    setDueMembersCount(0);
                }
            });
        }
    }, [dueOpened, dueDays]);

    function handleTabChange(value: string | null) {
        setActiveTab(value);
        switch (value) {
            case "7":
                setDueDays(7);
                break;
            case "14":
                setDueDays(14);
                break;
            case "isteklo":
                setDueDays(-1);
                break;
        }
    }
    
    return (
        <>
            <Modal opened={dueOpened} onClose={() => setDueOpened(false)} centered title="Članovi pred istek članstva">
                <Tabs value={activeTab} onTabChange={(value) => handleTabChange(value)} variant="pills" >
                    <Tabs.List>
                        <Tabs.Tab value="7">7 dana</Tabs.Tab>
                        <Tabs.Tab value="14">14 dana</Tabs.Tab>
                        <Tabs.Tab value="isteklo">Članstvo isteklo</Tabs.Tab>
                    </Tabs.List>

                    <Tabs.Panel value="7"><SoonDueScroll dueMembers={dueMembers} /></Tabs.Panel>
                    <Tabs.Panel value="14"><SoonDueScroll dueMembers={dueMembers} /></Tabs.Panel>
                    <Tabs.Panel value="isteklo"><SoonDueScroll dueMembers={expiredMembers} /></Tabs.Panel>
                </Tabs>
            </Modal>
            <Tooltip label="Članovi pred istek članstva" position="top" withArrow>
                <ActionIcon onClick={() => { setDueOpened(true); }} size="lg" variant="filled" color="red">
                    <IconClock size="24" />
                </ActionIcon>
            </Tooltip>
        </>
    )
}