import { showError, } from '../../utils/showPopup';
import { getDefaultHeaders, getFormHeaders } from '../util/getRequestHeaders';

const getMetricData = async (metric, month, year) => {
    const response = await fetch("/api/stats/getmetric", {
        method: "POST",
        headers: getDefaultHeaders(),
        body: JSON.stringify({metric, month, year}) // If metric is passed as undefined (not passed), it will return all the metrics
    })
    const json = await response.json()

    if (!response.ok) {
        showError(json.error)
        return {}
    } else if (response.ok) {
        return json.metricData
    }
}

export const useMetricData = () => {
    const getMetric = async (metric) => {
        // Fetches a metric for this year. If no metric is passed, returns them all.
        let now = new Date()
        let month = now.getMonth()+1; // Months are 0-based in JS.
        let year = now.getFullYear();
        return await getMetricData(metric, month, year);
    }

    const getPreviousMetric = async (metric) => {
        // Fetches a metric for the previous month, if no metric is passed, returns them all.
        // If metric was not collected at given time, returns "No data available for this month"
        let now = new Date()
        let month = now.getMonth(); // Months are 0-based in JS, so this function will actually return the the "previous" month
        let year = now.getFullYear();
        if (now.getMonth() === 0) {
            month = 12;
            year = year - 1;
        }

        return await getMetricData(metric, month, year);
    }

    const requestMetricUpdate = async () => {
        // Request the server to update the metrics
        const response = await fetch("/api/stats/updatemetrics", {
            method: "GET",
            headers: getFormHeaders()
        })
        const json = await response.json()

        if (!response.ok) {
            showError(json.error)
            return false
        } else if (response.ok) {
            return true
        }
    }

    return { getMetric, getPreviousMetric, requestMetricUpdate }

}