import { Avatar, Box, Button, Divider, Group, ScrollArea, Text, Title } from "@mantine/core";
import { IconUsers, IconTrash } from "@tabler/icons-react";
import acronymFromName from "../../utils/formatName";
import { useDeleteEmployee, useGetEmployees } from "../../hooks/useEmployeesHook";
import { useEffect, useState } from "react";
import { useAuthContext } from "../../hooks/useAuthContext";

interface EmployeeOverviewProps {
  callback: () => void;
}

function EmployeeOverview({ callback }: EmployeeOverviewProps) {
  const { get } = useGetEmployees();
  const { remove } = useDeleteEmployee();
  const [employees, setEmployees] = useState([]);
  const { user } = useAuthContext();
  const [update, setUpdate] = useState(false)

  const handleKick = async (employee: any) => {
    if ((await remove(employee._id)) === true) {
      setUpdate(!update)
      return;
    }
  };

  useEffect(() => {
    get().then((data) => {
      var employees = data.map((employee: { _id: string, name: string; color: string }) => ({
        _id: employee._id,
        name: employee.name,
        color: employee.color,
      }));
      setEmployees(employees);
    });
  }, [update]);

  return (
    <>
      <ScrollArea style={{ height: "50vh" }}>
        <Group>
          <IconUsers size="30"></IconUsers>
          <Title order={2}>Pregled djelatnika</Title>
        </Group>
        <Divider mt="md" mb="lg" />
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "left",
            alignItems: "center",
            marginRight: "30px",
            outline: "10px",
            outlineColor: "black",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            {employees.map((employee: { name: string; color: string }) => (
              <div
                key={employee.name}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Avatar color={employee.color} size={40} radius={40}>
                      {acronymFromName(employee.name)}
                    </Avatar>
                    <Text size="lg" style={{ marginLeft: "10px" }}>
                      {employee.name}
                    </Text>
                  </div>
                  {user.employee.name === "Administrator" && employee.name !== "Administrator" ? (
                    <Button
                      color="red"
                      leftIcon={<IconTrash size={14} />}
                      onClick={async () => await handleKick(employee)}
                    >
                      Ukloni
                    </Button>
                  ) : null}
                </div>
              </div>
            ))}
          </div>
        </Box>
      </ScrollArea>
    </>
  )
}

export default EmployeeOverview