import { showError, showLoading, updateLoading, showSuccess } from '../utils/showPopup'
import { getDefaultHeaders } from "./util/getRequestHeaders";

export const useUserEnteredGym = () => {
    const enterGym = async (_id) => {
        // const loadingId = showLoading("Entering gym...")
        try {
            const response = await fetch("/api/gym/entergym", {
                method: "POST",
                headers: getDefaultHeaders(),
                body: JSON.stringify({ "_id": _id })
            })
            const json = await response.json()
            // updateLoading(loadingId, false)
            
            if (!response.ok) {
                showError(json.error)
            } else {
                showSuccess("Korisnik je uspješno ušao u teretanu.")
                return true
            }
        } catch (error) {
            // updateLoading(loadingId, false)
            showError("Error, pokušajte ponovno.")
        }
    }

    return { enterGym }
}