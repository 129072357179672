import { useParams } from "react-router-dom";
import {
  createStyles,
  Container,
  Image,
  Title,
  Text,
  Button,
  Group,
  Center,
  Notification,
  LoadingOverlay,
  Code,
  Overlay,
  Box,
  Stack,
  Badge,
} from "@mantine/core";
import { useEffect, useState } from "react";
import { FetchUserByRFID } from "../hooks/MembersHook";
import { useAuthContext } from "../hooks/useAuthContext";
import { IconCheck, IconClipboard, IconClipboardCheck, IconX } from "@tabler/icons-react";
import { useUserEnteredGym } from "../hooks/useUserEnteredGym";
import formatMinutes from "../utils/formatMinutes";
import ReturnImgSrc from "../utils/imageSrc";

const useStyles = createStyles((theme) => ({
  root: {
    height: "100vh",
    overflow: 'hidden',
    position: 'relative',
    backgroundColor: theme.fn.variant({
      variant: "filled",
      color: theme.primaryColor,
    }).background,
  },

  title: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    textAlign: "center",
    fontWeight: 900,
    fontSize: 38,
    color: theme.white,
    zIndex: 2,

    [theme.fn.smallerThan("sm")]: {
      fontSize: 32,
    },
  },

  description: {
    maxWidth: 540,
    margin: "auto",
    marginTop: theme.spacing.xl,
    marginBottom: theme.spacing.xl * 1.5,
    color: theme.colors[theme.primaryColor][1],
    zIndex: 2,
  },
}));

interface MembershipType {
  weekly: number;
  remaining: number;
}

interface Data {
  name: string;
  membership_type: MembershipType;
}

export default function RFIDDisplay() {
  const { classes } = useStyles();
  const { enterGym } = useUserEnteredGym();
  const { rfid_id } = useParams();
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(true);
  
  const [data, setData] = useState<Data>({
    name: '',
    membership_type: {
      weekly: -1, // Default or placeholder value
      remaining: 0,
    },
  });

  const [name, setName] = useState("");
  const [image, setImage] = useState(null);
  const [duesPaid, setDuesPaid] = useState(false);
  const [nastavio, setNastavio] = useState(false);
  const [id, setId] = useState("");
  const [copied, setCopied] = useState(false);

  const [dateRead, setDateRead] = useState(new Date())

  const [remainingStatus, setRemainingStatus] = useState(false);
  const [strictVisit, setStrictVisit] = useState(false);

  function ComplexMembershipDisplay() {
    if (data && data.membership_type.weekly != -1) {
      return (
        <>
          <Badge sx={{position: 'absolute', right: '0'}}>Preostalo dana: {data.membership_type.remaining}</Badge>
        </>)
    }
  }

  function defineMembership(membership_type: any) {
    const { remaining, strict_visit } = membership_type;
    setRemainingStatus(remaining != 0);
    if (strict_visit) {
      const currentTime = new Date();
      const currentHour = currentTime.getHours();

      if (currentHour >= 14) {
        setStrictVisit(true);
      }
    }
  }

  const copyToClipboard = async (id: string | undefined) => {
    if (id) {
      navigator.clipboard.writeText(id);
      setCopied(true); setTimeout(() => {
        setCopied(false);
      }, 2000);
    }
  }

  const handleEnterGym = async () => {
    let success = await enterGym(id);
    if (success) {
      setNastavio(true);
    }
  };

  useEffect(() => {
    FetchUserByRFID(rfid_id).then((result) => {
      if (result) {
        let success = result[0];
        if (success) {
          let user = result[1];
          setData(user);
          setName(user.name);
          setImage(user.image);
          setLoading(false);
          setId(user._id);
          setDuesPaid(Date.parse(user.date_due) > Date.now());
          defineMembership(user.membership_type);
        }
        setLoading(false);
        setSuccess(result[0]);
      }
    });
  }, [success]);

  return data ? (
    <div className={classes.root}>

      <img src="../img/rfid-hand.svg" alt="RFID Hand" style={{
        position: 'absolute',
        bottom: '-10%',
        left: '0%',
        width: '100%',
        textAlign: 'left',
        overflow: 'hidden',
        maxWidth: '30%',
        zIndex: 0,
        opacity: 0.7,
        pointerEvents: 'none',
        height: 'auto'
      }} />
      <Center sx={{ height: "100vh" }}>
        <Container sx={{ zIndex: 2 }}>
          <LoadingOverlay visible={false} overlayBlur={2} />
          {!success ? (
            <>
              <Center>
                <Text size="xs" weight="450" color="white">KARTICA OČITANA ({dateRead.getHours()}:{formatMinutes(dateRead.getMinutes())}):</Text>
              </Center>
              <Center>
                <Title order={1} weight="900" color="black" opacity="0.5" size="70px">{rfid_id}</Title>
              </Center>
              <Center>
                <Button mt="lg" mb="lg" variant="white" size="md" sx={{ zIndex: 2 }}
                  color={copied ? "green" : "blue"}
                  leftIcon={copied ? <IconClipboardCheck size="24" /> : <IconClipboard size="24" />}
                  onClick={() => { copyToClipboard(rfid_id) }}>
                  {copied ? "Kopirano u međuspremnik!" : "Kopiraj u međuspremnik"}
                </Button>
              </Center>
            </>
          ) : null}
          <Center>
            {success ? (
              <Image
                mt="lg"
                mb="lg"
                src={ReturnImgSrc(image)}
                sx={{ maxWidth: "250px", maxHeight: "250px" }}
              />
            ) : null}
          </Center>
          <Title mt="50px" className={classes.title}>
            {success
              ? name + " je prislonio/la karticu!"
              : "Nema korisnika koji je povezan s ovom karticom..."}
          </Title>
          {success ? (
            <Notification
              mt="lg"
              icon={
                duesPaid && remainingStatus && !strictVisit ? (
                  <IconCheck size={18} />
                ) : (
                  <IconX size={18} />
                )
              }
              color={
                duesPaid && remainingStatus && !strictVisit ? "teal" : "red"
              }
              disallowClose
              title="Status članarine"
            >
              <Box sx={{ position: 'relative' }}>
                {strictVisit
                  ? "Vrijeme dolaska je prošlo."
                  : !duesPaid
                    ? "Članarina je istekla."
                    : !remainingStatus
                      ? "Prekoračen broj dana."
                      : "Članarina vrijedi!"}
                {ComplexMembershipDisplay()}
              </Box></Notification>
          ) : null}
          <Text size="lg" align="center" className={classes.description}>
            {success
              ? "Ako se pred vama nalazi korisnik sa slike i njegova članarina je aktivna, molimo Vas da kliknete Nastavi."
              : "Kako biste povezali korisnika s ovom karticom, kopirajte navedeni tekst gore i zaljepite ga bez razmaka pod 'RFID' stavku pri uređivanju korisničkih podataka."}
          </Text>
          <Group position="center">
            <Button
              disabled={
                nastavio ||
                !success ||
                !duesPaid ||
                !remainingStatus ||
                strictVisit
              }
              variant="white"
              size="md"
              onClick={handleEnterGym}
            >
              Nastavi
            </Button>
          </Group>
          {/* {nastavio ? (
            <Text
              mt="xs"
              size="lg"
              align="center"
              className={classes.description}
            >
              Korisnik uspješno dodan u prostor teretane, možete zatvoriti ovaj
              prozor.
            </Text>
          ) : null} */}
        </Container>
      </Center>
    </div>
  ) : <>No data loaded</>;
}
