import {
    TextInput,
    PasswordInput,
    Anchor,
    Paper,
    Text,
    Container,
    Group,
    Button,
    Center
} from '@mantine/core';

import { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useLogin } from "../hooks/useLogin"
import { IconAt, IconPassword, IconUserCircle } from "@tabler/icons-react";

export default function Login() {
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [employeeKey, setEmployeeKey] = useState("")
    const { login, isLoading } = useLogin()
    const navigate = useNavigate();

    const handleSubmit = async (event: any) => {
        event.preventDefault()
        if (await login(email, password, employeeKey) == true) {
            navigate("/dashboard");
        }
    }
    return (
        <div style={{ backgroundImage: 'url("img/login_splash6.png")', }}>
            <Center style={{
                minWidth: "100%",
                minHeight: "100vh",
            }}>
                <Container size={600} my={40}>
                    <img src="img/logo_long.png" width="50%" />
                    <Paper withBorder shadow="md" p={30} mt={30} radius="md" style={{ minWidth: "40vh", }}>
                        <form onSubmit={handleSubmit}>
                            <TextInput
                                label="Email"
                                placeholder="Vaša E-mail adresa"
                                size="md"
                                onChange={(event) => setEmail(event.target.value)}
                                value={email}
                                icon={<IconAt size={14} />} 
                            />

                            <PasswordInput
                                label="Lozinka"
                                placeholder="Vaša lozinka"
                                mt="md"
                                size="md"
                                onChange={(event) => setPassword(event.target.value)}
                                value={password}
                                icon={<IconPassword size={14} />} 
                            />

                            <PasswordInput
                                label="Šifra djelatnika"
                                placeholder="Šifra djelatnika"
                                mt="md"
                                size="md"
                                onChange={(event) => setEmployeeKey(event.target.value)}
                                value={employeeKey}
                                icon={<IconUserCircle size={14} />} 
                            />
                            <Group position="apart" mt="lg">
                                {/*<Checkbox label="Zapamti me" ref={ref}sx={{ lineHeight: 1 }} />*/}
                                {/* <Anchor<'a'> onClick={(event) => event.preventDefault()} href="#" size="sm">
                                    Zaboravio si lozinku?
                                    </Anchor> */}
                            </Group>
                            <Button
                                fullWidth mt="xl"
                                disabled={isLoading}
                                onClick={handleSubmit}
                                type="submit"
                            >
                                Prijava
                            </Button>
                        </form>
                        <Text color="gray" size="sm" align="center" mt={10}>
                            Nemaš račun?{' '}
                            <Anchor<'a'> href="/signup" weight={700}>
                                Registriraj se
                            </Anchor>
                        </Text>
                    </Paper>
                </Container>
            </Center>
        </div>
    );
}