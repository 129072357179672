import { Button, ColorPicker, Divider, Group, PasswordInput, ScrollArea, SimpleGrid, TextInput, Title, Text, ColorInput } from "@mantine/core";
import { IconUserPlus } from "@tabler/icons-react";
import { useCreateEmployee } from "../../hooks/useEmployeesHook";
import { useForm } from "@mantine/form";
import { useState } from "react";
import NonDiacriticTextInput from "../NonDiacriticTextInput";

interface FormValues {
  name: string;
  surname: string;
  password: string;
  color: string;
}

interface NewEmployeeProps {
  callback: () => void;
}

function NewEmployee({callback}:NewEmployeeProps) {
  const { create } = useCreateEmployee();
  const [confirmNewPassword, setConfirmNewPassword] = useState("")
  const [passwordNotSameError, setPasswordNotSameError] = useState("")
  const [color, setColor] = useState("");

  const employeeForm = useForm<FormValues>({
    initialValues: {
      name: "",
      surname: "",
      password: "",
      color: "cyan",
    },
  });

  const handleSubmit = async (values: any) => {

    if (values.password != confirmNewPassword) {
      setPasswordNotSameError("Lozinke nisu iste!")
      return;
    }

    const full_name = `${values.name} ${values.surname}`;
    if ((await create(full_name, values.password, values.color)) === true) {
      employeeForm.reset();
      callback();
    }
  };

  return (
    <>
      <ScrollArea style={{ height: "50vh" }}>
        <Group>
          <IconUserPlus size="30"></IconUserPlus>
          <Title order={2}>Dodavanje novog djelatnika</Title>
        </Group>
        <Divider mt="md" mb="lg" />
        <form
          onSubmit={employeeForm.onSubmit((values) => handleSubmit(values))}
        >
          <SimpleGrid cols={2} spacing="md">
            <NonDiacriticTextInput withAsterisk label="Ime" placeholder="Pero" {...employeeForm.getInputProps("name")}></NonDiacriticTextInput>
            <NonDiacriticTextInput withAsterisk label="Prezime" placeholder="Peric" {...employeeForm.getInputProps("surname")}></NonDiacriticTextInput>
            {/* <TextInput withAsterisk label="Ime" placeholder="Pero" {...employeeForm.getInputProps("name")} />
            <TextInput withAsterisk label="Prezime" placeholder="Perić" {...employeeForm.getInputProps("surname")} /> */}
          </SimpleGrid>
          <PasswordInput withAsterisk label="Lozinka" placeholder="Lozinka/šifra djelatnika" {...employeeForm.getInputProps("password")} error={passwordNotSameError}/>
          <Text c="dimmed" size="xs">Ovu šifru djelatnik koristi prilikom prijave na uslugu GymChain.</Text>
          <PasswordInput withAsterisk label="Potvrdite lozinku" placeholder="Potvrdite lozinku/šifru" onChange={(event)=>{setConfirmNewPassword(event.target.value)}} error={passwordNotSameError} />
          <ColorInput placeholder="Odaberite boju djelatnika" label="Boja djelatnika" format="hex" withAsterisk withPicker={false}
          swatches={['#fa5252', '#e64980', '#be4bdb', '#7950f2', '#4c6ef5', '#228be6', '#15aabf', '#12b886', '#40c057', '#82c91e', '#fab005', '#fd7e14']}
          onChange={(color) => {
            setColor(color);
            employeeForm.setFieldValue("color", color);
          }}
          />
          <Button type="submit" variant="filled" mt="lg" style={{ backgroundColor: color }}>
            Dodaj djelatnika
          </Button>
        </form>
      </ScrollArea>
    </>
  )

}

export default NewEmployee