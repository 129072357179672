import { showError, } from '../../utils/showPopup';
import { getDefaultHeaders, getFormHeaders } from '../util/getRequestHeaders';

export const useFrequencyData = () => {
    const getFrequencyData = async (day) => {
        // day: 0 is monday, 6 is sunday
        const response = await fetch("/api/stats/getfrequency", {
            method: "POST",
            headers: getDefaultHeaders(),
            body: JSON.stringify({day}) // If day is passed as undefined (not passed), it will return the data for all 7 days.
        })
        const json = await response.json()

        if (!response.ok) {
            showError(json.error)
            return {}
        } else if (response.ok) {
            return json.frequency_array
        }
    }
    return { getFrequencyData }
}