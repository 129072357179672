import { SimpleGrid,  } from '@mantine/core';
import Metric from './Metric';

interface MetricTrackerProps {
    metrics: string[];
    titles: string[];
}

export default function MetricTracker({ metrics, titles }: MetricTrackerProps) {
    const metricComponents = metrics.map((metric: string, index: number) => {
        return (
            <Metric metric={metric} title={titles[index]}></Metric>
        );
    });

    return (
        <div>
            <SimpleGrid cols={3}>{metricComponents}</SimpleGrid>
        </div>
    );
}