import { Accordion, Avatar, Grid, Group, ScrollArea, Text, Tooltip } from '@mantine/core';
import { useEffect, useState } from 'react';
import { useIndoorTracker } from '../../hooks/Statistics/useIndoorTracker';
import { FetchUserById } from '../../hooks/MembersHook';
import ReturnImgSrc from '../../utils/imageSrc';

export default function IndoorTracker() {
  const [trackerCount, setTrackerCount] = useState(0)
  const [members, setMembers] = useState<any[]>([]);

  const { getMembersIndoors } = useIndoorTracker();

  const fetchMembersIndoors = async () => {
    await getMembersIndoors().then(async (active_users) => {
      await Promise.all(active_users.map(async (user: any) => {
          const user_info = await FetchUserById(user._id);
          user.name = user_info.name;
          user.image = await ReturnImgSrc(user_info.image);
      }));
      setMembers(active_users);
      console.log(active_users);
  });
  }

  useEffect(() => {
    fetchMembersIndoors();
  }, []);

  return (
    <Accordion variant="contained">
      <Accordion.Item value="indoor-tracker" onClick={() => { setTrackerCount(200) }} style={{ backgroundColor: "white" }}>
        <Accordion.Control>
          <Text c="dimmed" tt="uppercase" fw={700} fz="xs">
            ČLANOVI U TERETANI
          </Text>
        </Accordion.Control>
        <Accordion.Panel>
          <Group>
              {members.map((member, index) => (
                <Tooltip key={index} inline label={`${member.name}, ulaz: ${new Date(member.entryTime * 1000).toISOString().substr(11, 8)}`}>
                  <Avatar src={member.image || "avatar.png"} alt="it's me" />
                </Tooltip>
              ))}
            </Group>
        </Accordion.Panel>
      </Accordion.Item>
    </Accordion>
  );
}