import { getLastUpdate } from "../util/getLastUpdate";
import { getDefaultHeaders } from "../util/getRequestHeaders";

export const useGetMembers = () => {
    const getMembersInfo = async () => {
        let response = await fetch("/api/gym/getmembersinfo", {
            method: "GET",
            headers: getDefaultHeaders(),
            body: JSON.stringify()
        });

        if (!response.ok) {
            throw new Error(response.statusText);
        }

        let response_json = await response.json();
        let { totalPages, totalUsers } = response_json

        return {totalPages, totalUsers}
    }

    const getMembers = async (page) => {
        let response = await fetch("/api/gym/getmembers", {
            method: "POST",
            headers: getDefaultHeaders(),
            body: JSON.stringify({page})
        });

        if (!response.ok) {
            throw new Error(response.statusText);
        }
    
        let response_json = await response.json();
        let { minimalList, paginatedList } = response_json
        
        return { minimalList, paginatedList };
    }

    const getMembersByGroup = async (_groupId) => {
        let response = await fetch("/api/gym/getmembersbygroup", {
            method: "POST",
            headers: getDefaultHeaders(),
            body: JSON.stringify({_id: _groupId})
        });

        if (!response.ok) {
            throw new Error(response.statusText);
        }

        let response_json = await response.json();
        let members = response_json.members
        return members
    }

    const getMemberById = async (_id) => {

    }

    const getMemberByRfid = async (rfid) => {

    }


    const getSoonDueMembers = async (days) => {

    }

    return {getMembersInfo, getMembers, getMembersByGroup, getMemberById, getMemberByRfid, getSoonDueMembers}
}