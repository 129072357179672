import { showError, } from '../../utils/showPopup';
import { getDefaultHeaders } from '../util/getRequestHeaders';

export const useIndoorTracker = () => {
    const getMembersIndoors = async (day) => {
        const response = await fetch("/api/stats/getindoors", {
            method: "GET",
            headers: getDefaultHeaders(),
            body: JSON.stringify()
        })
        const json = await response.json()

        if (!response.ok) {
            showError(json.error)
            return {}
        } else if (response.ok) {
            return json.active_users
        }
    }
    return { getMembersIndoors }
}