import { useState, useEffect } from "react";
import { SegmentedControl, ScrollArea, Group, Button, Center, Title, ActionIcon, Flex } from "@mantine/core";
import { TimeRangeInput } from "@mantine/dates";
import { IconCircleX, IconClockPause, IconClockPlay, IconPlus } from "@tabler/icons-react";

// Define the type for a TimeRange
export type TimeRange = [Date | null, Date | null];

// Define the type for the schedule
export interface Schedule {
  PON: TimeRange[];
  UTO: TimeRange[];
  SRI: TimeRange[];
  CET: TimeRange[];
  PET: TimeRange[];
  SUB: TimeRange[];
  NED: TimeRange[];
}

interface WeeklyTimePickerProps {
  onScheduleChange: (schedule: Schedule) => void; // Callback to send the schedule data to the parent
  verticalDisplay?: boolean;
  initialSchedule?: Schedule;
}

export let convertStringToDate = (timeRange: TimeRange): TimeRange => {
    // If the value is a string, convert it to a Date object, else leave it as is
    return [
      timeRange[0] ? new Date(timeRange[0]) : null,
      timeRange[1] ? new Date(timeRange[1]) : null,
    ];
};

export let cleanSchedule = (schedule: Schedule, sort?: boolean): Schedule => {
  let newSchedule = { ...schedule };

  // Iterate over each day
  Object.keys(newSchedule).forEach((day) => {
    // Filter out entries with null values
    newSchedule[day as keyof Schedule] = newSchedule[day as keyof Schedule].filter(
      (timeRange) => !(timeRange[0] === null || timeRange[1] === null)
    );

    // Sort by the start time if sort is true
    if (sort) {
      newSchedule[day as keyof Schedule] = newSchedule[day as keyof Schedule].sort(
        (a, b) => new Date(a[0]!).getTime() - new Date(b[0]!).getTime()
      );
    }
  });

  return newSchedule;
};

const WeeklyTimePicker = ({ onScheduleChange, verticalDisplay, initialSchedule, }: WeeklyTimePickerProps) => {
  const [selectedDay, setSelectedDay] = useState<keyof Schedule>("PON");
  const [schedule, setSchedule] = useState<Schedule>({
    PON: [],
    UTO: [],
    SRI: [],
    CET: [],
    PET: [],
    SUB: [],
    NED: [],
  });

  // Use useEffect to set the initialSchedule only once on mount
  useEffect(() => {
    if (initialSchedule) {
      setSchedule(initialSchedule);
    }
  }, [initialSchedule]);

  // Notify the parent component whenever the schedule changes
  useEffect(() => {
    onScheduleChange(schedule);
  }, [schedule]);

  // Add a new time range for the selected day
  const addTimeRange = (day: keyof Schedule) => {
    setSchedule((prevSchedule) => ({
      ...prevSchedule,
      [day]: [...prevSchedule[day], [null, null]],
    }));
  };

  // Remove a specific time range
  const removeTimeRange = (day: keyof Schedule, index: number) => {
    setSchedule((prevSchedule) => ({
      ...prevSchedule,
      [day]: prevSchedule[day].filter((_, i) => i !== index),
    }));
  };

  // Handle change in the TimeRangeInput
  const handleTimeRangeChange = (day: keyof Schedule, index: number, value: TimeRange) => {
    setSchedule((prevSchedule) => ({
      ...prevSchedule,
      [day]: prevSchedule[day].map((timeRange, i) => (i === index ? value : timeRange)),
    }));
  };

  return (
    <>
      <Flex direction={verticalDisplay ? "row" : "column"} gap="md">
      <SegmentedControl
        value={selectedDay}
        onChange={(value) => setSelectedDay(value as keyof Schedule)}
        fullWidth
        data={["PON", "UTO", "SRI", "CET", "PET", "SUB", "NED"]}
        mb="md"
        orientation={verticalDisplay  ? "vertical" : "horizontal"}
      />

      <ScrollArea style={{ height: 200 }} mb="md">
        {schedule[selectedDay].length === 0 ? (
          <Center>
            <Title color="dimmed">Prazno</Title>
          </Center>
        ) : null}
        {schedule[selectedDay]?.map((timeRange, index) => (
          <Center key={index}>
            <Group mt="xs">
              <TimeRangeInput required={true} withAsterisk
                icon={<IconClockPlay size={24} color="#60d15e" />} rightSection={<IconClockPause size={24} color="#d15e5e" />}
                value={timeRange}
                onChange={(value) => handleTimeRangeChange(selectedDay, index, value)}
              />
              <ActionIcon color="red" onClick={() => removeTimeRange(selectedDay, index)}>
                <IconCircleX size={24} />
              </ActionIcon>
            </Group>
          </Center>
        ))}
      </ScrollArea>
      </Flex>

      <Flex align="center" justify={verticalDisplay ? "flex-start" : "center"}>
        <Button variant="outline" onClick={() => addTimeRange(selectedDay)} leftIcon={<IconPlus />} mb="md">
          {`Dodaj termin (${selectedDay})`}
        </Button>
      </Flex>
    </>
  );
};

export default WeeklyTimePicker;
