import { useEffect, useState } from 'react';
import { Group, Paper, Text, ThemeIcon } from '@mantine/core';
import { IconClockExclamation } from '@tabler/icons-react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { DaySelector } from './DaySelector';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const options_data = {
    responsive: true,
    plugins: {
        legend: { display: false },
        title: {
            display: false,
            text: 'Vremena najveće posjećenosti',
        },
    },
};

export default function FrequencyChart({ frequency_subarray, defaultDay, setFrequencyDay }: any) {
    const [chartData, setChartData] = useState({
        labels: ["00:00", "01:00", "02:00", "03:00", "04:00", "05:00", "06:00", "07:00", "08:00", "09:00", "10:00", "11:00", "12:00", "13:00", "14:00", "15:00", "16:00", "17:00", "18:00", "19:00", "20:00", "21:00", "22:00", "23:00"],
        datasets: [
            {
                label: "Ukupan broj posjeta",
                data: Array(24).fill(0), // Initialize with zeros
                backgroundColor: "rgba(34, 139, 230, 1)",
                borderColor: "rgba(24, 130, 220, 1)",
                borderWidth: 0
            }
        ]
    });

    useEffect(() => {
        // Update chart data whenever frequency_subarray changes
        setChartData(prevData => ({
            ...prevData,
            datasets: [
                {
                    ...prevData.datasets[0],
                    data: frequency_subarray
                }
            ]
        }));
    }, [frequency_subarray]);

    return (
        <Paper withBorder p="lg" radius="md" key={"frequency-time-chart"}>
            <Group position="apart">
                <Text c="dimmed" tt="uppercase" fw={700} fz="xs">
                    POSJEĆENOST
                </Text>
                <ThemeIcon
                    color="gray"
                    variant="light"
                    style={{
                        color: "#12b886" // : "#fa5252",
                    }}
                    size={38}
                    radius="md"
                >
                    <IconClockExclamation size={28} stroke={1.5} />
                </ThemeIcon>
            </Group>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "17vw" }}>
                <div style={{ height: "auto", width: "55%" }}>
                <DaySelector startDay={defaultDay} setDay={setFrequencyDay} />
                    <Bar options={options_data} data={chartData} />
                </div>
            </div>
        </Paper>
    );
}