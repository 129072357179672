import { Button, Center, Container, Grid, Paper, SimpleGrid, createStyles, Title, Text, useMantineTheme, Progress, Transition, LoadingOverlay, Group, Stack } from '@mantine/core';
import { useEffect, useState } from 'react';

import FrequencyChart from '../../components/statistic/FrequencyChart';
import { useInfo } from '../../hooks/Information/useInfo';
import { FetchUsers } from '../../hooks/MembersHook';
import { useToggle } from '@mantine/hooks';
import { useGetMembers } from '../../hooks/Members/useGetMembers';
import MetricTracker from '../../components/statistic/MetricTracker';
import IndoorTracker from '../../components/statistic/IndoorTracker';
import { useFrequencyData } from '../../hooks/Statistics/useFrequencyData';
import { useMetricData } from '../../hooks/Statistics/useMetricData';

function getTodayAsNumber() {
	const today = new Date();
	const day = today.getDay(); // 0 for Sunday, 1 for Monday, ..., 6 for Saturday
	return day === 0 ? 6 : day - 1; // Adjust so 0 is Monday, ..., 6 is Sunday
}

export function Statistika() {
	const [frequencyDataset, setFrequencyDataset] = useState([]);
	const [frequencyDay, setFrequencyDay] = useState(getTodayAsNumber())
	const { getFrequencyData } = useFrequencyData()

	const [updateSwitch, toggleUpdateSwitch] = useState(false);
	const [metrics, setMetrics] = useState(["members", "newMembers", "extendedMembers"])
	const [metricTranslation, setMetricTranslation] = useState(["Članovi", "Novi članovi", "Produžena članstva"])
	const { requestMetricUpdate } = useMetricData()

	const today = new Date();
	const fetchFrequencyDataset = async () => {
		await getFrequencyData(frequencyDay).then((frequency_array) => {
			setFrequencyDataset(frequency_array);
		});
	}

	const requestUpdate = async () => {await requestMetricUpdate(); window.location.reload();}

	useEffect(() => {
		fetchFrequencyDataset();
	}, [frequencyDay, updateSwitch]);

	return (
		<>
			<Title m="md">Statistika</Title>
			<Text color="dimmed" align="center">
				Podaci se ažuriraju automatski svaka 3h ili klikom <Text component="span" color="blue" style={{ cursor: 'pointer' }} onClick={requestUpdate}>ovdje</Text>
			</Text>
			<Container my="md">
				<Center sx={{ height: "80vh" }}>
					<SimpleGrid cols={1} spacing="md" sx={{ maxHeight: "80vh", width: "100%" }} breakpoints={[{ maxWidth: 'lg', cols: 1 }]}>
						<FrequencyChart frequency_subarray={frequencyDataset} defaultDay={frequencyDay} setFrequencyDay={setFrequencyDay} />
						<MetricTracker metrics={metrics} titles={metricTranslation}></MetricTracker>
						<IndoorTracker></IndoorTracker>
					</SimpleGrid>
				</Center>
			</Container>
		</>
	)
}

export default Statistika