export default function ReturnImgSrc(image: any) {
    const base64 = window.btoa(
        new Uint8Array(image.data.data).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            ""
        )
    );
    const imgSrc = `data:${image.contentType};base64,${base64}`;
    return imgSrc;
}
