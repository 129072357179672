import { IconArrowDownRight, IconArrowUpRight } from "@tabler/icons-react";
import { Group, Paper, SimpleGrid, Skeleton, Text, ThemeIcon } from '@mantine/core';
import { useMetricData } from "../../hooks/Statistics/useMetricData";
import { useEffect, useState } from 'react';

interface MetricDisplay {
    title: string,
    value: string | number,
    delta: number, // Difference from previous month
    diff: number, // Percentage difference from previous month
    missing: boolean,
    missingPrev: boolean,
}

export default function Metric({ metric, title }: { metric: string, title: string }) {
    const [metricData, setMetricData] = useState<MetricDisplay | undefined>(undefined);
    const { getMetric, getPreviousMetric } = useMetricData()
    
    const fetchMetric = async () => {
        let metricData: MetricDisplay = {
            title: metric,
            value: 0,
            delta: 0,
            diff: 0,
            missing: false,
            missingPrev: false,
        }
        await getMetric(metric).then(async (metric_value: any) => {
            if (metric_value == "No data for this month" || metric_value == "Metric not found") {
                metricData.missing = true;
                metricData.missingPrev = true;
                setMetricData(metricData); return;
            } else {
                metricData.value = metric_value;
                await getPreviousMetric(metric).then((previous_metric_value: any) => {
                    if (previous_metric_value == "No data for this month" || previous_metric_value == "Metric not found") {
                        metricData.missingPrev = true;
                    } else {
                        metricData.delta = metric_value - previous_metric_value;
                        metricData.diff = Math.floor((metricData.delta / previous_metric_value) * 100);
                    }
                    setMetricData(metricData); return;
                });
            }
        });
        setMetricData(metricData);
    }

    useEffect(() => {
        fetchMetric();
    }, [metric]);

    const DiffIcon = metricData && metricData.diff > 0 ? IconArrowUpRight : IconArrowDownRight;
    return metricData ? (
        <Paper withBorder p="md" radius="md" key={title}>
            <Group position="apart">
                <div>
                    <Text c="dimmed" tt="uppercase" fw={700} fz="xs">
                        {title}
                    </Text>
                    <Text fw={700} fz="xl">
                        {metricData.missing ? "Nema podataka" : metricData.value}{"   "}
                        {!metricData.missing && (
                            <Text component="span" ml="3px" c={metricData.diff > 0 ? 'teal' : 'red'} size="xs" fw={700}>
                                {metricData.diff}%
                                <DiffIcon size={16} style={{ marginBottom: 4 }} stroke={1.5} />
                            </Text>
                        )}
                    </Text>
                </div>
                {!metricData.missing && (
                    <ThemeIcon
                        color="gray"
                        variant="light"
                        style={{
                            color: metricData.diff > 0 ? "#12b886" : "#fa5252",
                        }}
                        size={38}
                        radius="md"
                    >
                        <DiffIcon size={28} stroke={1.5} />
                    </ThemeIcon>
                )}
            </Group>
            <Text c="dimmed" fz="sm" mt="md">
                {metricData.missingPrev ? "Nema podataka za prošli mjesec" : (
                    <>
                        {metricData.diff > 0 ? 'Povećanje' : 'Smanjenje'}{" za "}
                        <Text component="span" c={metricData.diff > 0 ? 'teal' : 'red'} fw={700}>
                            {Math.abs(metricData.delta)}
                        </Text>
                        {" u odnosu na prošli mjesec "}
                    </>
                )}
            </Text>
        </Paper>
    ) : <Skeleton height={150} />;
}