import { Badge, ScrollArea, Text, Avatar, Divider } from "@mantine/core"

export default function SoonDueScroll({ dueMembers }: any) {
	
	const sortedMembers = [...dueMembers].sort((a, b) => new Date(a.date_due).getTime() - new Date(b.date_due).getTime());
	function daysCounter(date_due: any) {
		const dueDate = new Date(date_due).getTime();
		const now = new Date().getTime();
		const difference = dueDate - now;
		const days = Math.floor(difference / (1000 * 60 * 60 * 24));
		return days;
	}

	function color(days: number) {
		if (days <= 3) {
			return "red";
		} else if (days <= 7) {
			return "orange";
		} else {
			return "yellow";
		}
	}

	return (
		<>
			<Divider mt="xs" mb="xs" />
			<Text size="xs" color="grey" w={600}>Broj članova: {dueMembers.length}</Text>
			<ScrollArea.Autosize
				maxHeight={500}
				sx={{ maxWidth: 500 }}
				mx="auto"
				placeholder=""
				onPointerEnterCapture={() => { }}
				onPointerLeaveCapture={() => { }}
			>
				{
					sortedMembers.map((member: any) => (
						<div
							style={{
								display: "flex",
								flexDirection: "row",
								alignItems: "center",
								justifyContent: "space-between",
								marginTop: "20px",
							}}
						>
							<div
								style={{
									display: "flex",
									justifyContent: "space-between",
									alignItems: "center",
								}}
							>
								<div
									style={{
										display: "flex",
										justifyContent: "space-between",
										alignItems: "center",
									}}
								>
									<Avatar src={member.image} size={40} radius={40} />
									<Text
										size="md"
										weight={500}
										style={{
											marginLeft: "10px",
										}}
									>
										{member.label}
									</Text>
								</div>
							</div>
							<Badge size="md" variant="outline" color={color(daysCounter(member.date_due))}>
								{Math.abs(daysCounter(member.date_due))} dana {daysCounter(member.date_due) >= 0 ? "do" : "od"} isteka
							</Badge>
						</div>
					))
				}
			</ScrollArea.Autosize >
		</>
	)
}